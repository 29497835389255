<template>
    <div class="bg-gradient-to-b from-blue-600 to-blue-500 py-20">
        <div class="inner text-center">
            <h2 class="text-white text-2xl font-bold">자주 묻는 질문</h2>
            <p class="text-white mt-6 mb-10">추가로 궁금하신 점은 전화로 문의해주세요</p>

            <div class="flex flex-wrap justify-between">
                <WhiteCard v-for="(card, index) in cards" :key="index" :title="card.title" :content="card.content" />
            </div>
        </div>
    </div>
</template>

<script>
import WhiteCard from "@/components/WhiteCard.vue";

export default {
    name: "MainFifth",
    components: {WhiteCard},
    data() {
        return {
            cards: [
                {title: "앱 개발 견적이 궁금합니다", content: "정확한 견적을 안내해드리기 위해서 고객사에서 준비하신 상세 기획서나 저희를 통해 제작하신 프로토타입이 있어야지만 상세 견적을 안내해드리고 있습니다. 우선 문의를 남겨주시면 진행 절차에 대해 응대해드리도록 하겠습니다."},
                {title: "관리자 페이지를 만들어주나요?", content: "견적 안내 단계에서 기본적인 관리자 페이지에 대한 견적을 함께 안내해드리고 있습니다. 대략적인 테이블 갯수를 파악하여 각 테이블을 수정, 삭제, 조회, 생성할 수 있는 관리자 페이지를 기본적으로 제작하고 있으며, 별도의 관리 기능이 필요하시다면 그에 대한 개발 공수도 함께 안내해드리게 됩니다"},
                {title: "앱스토어, SNS, 호스팅 등 각종 계정 생성은 어떻게 하나요?", content: "고객사 프로젝트에 필요한 소셜 로그인 계정, 결제사 계정, 서버와 데이터베이스 호스팅 계정, 앰스토어 계정 등을 모두 고객사 명의로 만드실 수 있도록 안내드리고 있습니다. 계정이 필요한 시점에 가이드 문서를 전달해드리니 걱정하지 않으셔도 됩니다. 고객사 명의로 만드는 이유는 추후 개발 완료 후에도 고객사 내부 운용이 원활할 수 있도록 하기 위해서 입니다."},
                {title: "개발이 끝나고 하자보수가 가능한가요? 유지보수를 의뢰할 수 있나요?", content: "개발 완료 후 6개월은 무상 유지보수 기한으로 하자에 대해 무상으로 대응해 드립니다. 6개월이 지난 후엔 연간 유지보수 계약을 맺을 수 있습니다. 3~4년째 유지보수를 맡기고 계신 고객사들도 있습니다. 구현하기로 협의된 기능의 구현 완료, 서비스 공개 또는 앱스토어 출시, 고객사 사정에 의한 개발 중단을 개발 완료로 보고 있습니다."},
                {title: "예산이 한정되어 있는데 개발 범위를 어떻게 정해야 하나요?", content: "고객사의 예산에 맞추어 개발 범위를 함께 조율해드립니다. 같은 중고거래 플랫폼이라 하더라도 예산이 3천만 원이냐, 5천만 원이냐, 8천만 원이냐에 따라 핵심적인 물품 포스팅과 최소한의 소통 기능만 구현할지, 결제나 정산을 구현할지, 채팅과 커뮤니티 기능을 구현할지를 개발자가 견적 계산 단계에서 함께 고민해드립니다"},
            ]
        }
    }
}
</script>

<style scoped>

</style>