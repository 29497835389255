<template>
    <template v-for="(list, index) in payList" :key="index">
        <div>
            <h2 class="font-bold mt-5 mb-3.5 text-2xl">{{ list['mainTitle'] }}</h2>
            <div  class="flex justify-start flex-wrap">
                <template v-for="(data, key) in list['data']" :key="key">
                    <PayBox @payBoxClick="updateTotal" :code="data['key']" :title="data['title']" :pay="data['pay']" :description="data['memo']"/>
                </template>
            </div>
        </div>
    </template>

    <div class="fixed bottom-2.5 left-0 right-0 mx-auto inner py-4 px-3.5 flex justify-between shadow-md items-center rounded-lg bg-white border-2 border-blue-500">
        <div class="font-semibold text-xl">
            예상 견적<br>
            {{ total }} <span class="text-base text-slate-500">만원</span>
        </div>
        <button class="rounded text-white text-center py-2.5 px-3.5 bg-slate-600 font-semibold text-sm" @click="togglePayTable">견적서 보기</button>
    </div>
    <PayTable v-if="showPayTable" :totalAmt="total" :list="estiData" @close="closePayTable" />
</template>

<script setup>
import PayBox from "@/components/PayBox.vue";
import PayTable from "@/views/estimate/PayTable.vue";

import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const payList       = computed(() => store.state.pay.list);
const total         = ref(0);
const showPayTable  = ref(false);
const estiData      = ref([]);

const updateTotal = (params) => {
    if(params['status'])
    {
        total.value += parseInt(params['amt']);
        estiData.value.push(params);
    }
    else
    {
        total.value -= parseInt(params['amt']);
        const index = estiData.value.findIndex(item => item.key === params['key']);

        if (index !== -1) 
        {
            estiData.value.splice(index, 1);
        }
    }
};

const togglePayTable = () => {
    if(estiData.value.length === 0)
    {
        alert('견적을 최소 하나 이상 선택하셔야합니다.');
        return false;
    }

    showPayTable.value = !showPayTable.value;
};

const closePayTable = () => {
    showPayTable.value = false;
};
</script>

<style scoped>

</style>