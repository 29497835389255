/**
 * @description 오늘일자
 */
export function getTodayDate()
{
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * @description 숫자 콤마 처리
 */
export function getCommas(value) 
{
    if(typeof value !== 'number')
    {
        return value;
    }
    
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @description 부가세 계산식(Y:포함, N:미포함)
 * @author 김원명, @version 1.0, @last date 2024/06/03
 * @param 부가세 구분, 금액
 * @return 부가세 구분에 따른 값
 */
export function getVatAmt(vat, amt) 
{
	if(vat === 'Y')
    {	
        //세액 계산
		return Math.round(amt * 0.1);
	}
    else if(vat === 'N')
    {	
        //세액 계산안함
		return 0;
	}
}