/**
 * @description 포트폴리오 정보 관련 vuex
 */
const portfolioModule = {
    namespaced : true, 
    state : {
        baseball : {
            title  : '야구 경기 매칭 플랫폼',
            memo   : '',
            date   : '2022-03-28',
            images : [
                { image: "baseball1", alt: "이미지1" },
                { image: "baseball2", alt: "이미지2" },
                { image: "baseball3", alt: "이미지3" },
                { image: "baseball4", alt: "이미지4" },
                { image: "baseball5", alt: "이미지5" },
                { image: "baseball6", alt: "이미지6" }
            ],
            gubun : 'mobile'
        },
        wecls    : {
            title  : '위클즈 강사 매칭 플랫폼',
            memo   : '강사용, 기관용, 관리자용 / 전체 100페이지 이하 개발',
            date   : '2023-12-03',
            images : [
                { image: "wecls1", alt: "이미지1" },
                { image: "wecls2", alt: "이미지2" },
                { image: "wecls3", alt: "이미지3" },
                { image: "wecls4", alt: "이미지4" },
                { image: "wecls5", alt: "이미지5" },
                { image: "wecls6", alt: "이미지6" },
            ],
            gubun : 'web'
        },
        kory    : {
            title   : '고려기업 조선ERP/MES',
            memo    : '조선소 생산 관리시스템 / 전체 100페이지 이하 개발',
            date    : '2023-07-03',
            images  : [
                { image: "kory1", alt: "이미지1" },
                { image: "kory2", alt: "이미지2" },
                { image: "kory3", alt: "이미지3" },
                { image: "kory4", alt: "이미지4" },
                { image: "kory5", alt: "이미지5" },
                { image: "kory6", alt: "이미지6" }
            ],
            gubun   : 'web'
        },
        planorder : {
            title   : '플랜오더 B2B 유통 플랫폼',
            memo    : '비규격 인테리어 매장, 제조공장, 유통사 매칭 플랫폼 / 250페이지 이하',
            date    : '2022-03-28',
            images  : [
                { image: "planorder1", alt: "이미지1" },
                { image: "planorder2", alt: "이미지2" },
                { image: "planorder3", alt: "이미지3" },
                { image: "planorder4", alt: "이미지4" },
                { image: "planorder5", alt: "이미지5" },
                { image: "planorder6", alt: "이미지6" },
                { image: "planorder7", alt: "이미지7" },
                { image: "planorder8", alt: "이미지8" }
            ],
            gubun   : 'mobile'
        }
    }
};
  
export default portfolioModule;