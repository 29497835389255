<template>
    <div class="bg-first w-full bg-cover bg-no-repeat bg-center flex items-center">
        <div class="inner text-center">
            <img src="@/assets/images/icon-circle01.png" alt="01번" title="01번" class="mx-auto" />
            <h2 class="font-semibold text-gray-900 text-xl md:text-3xl lg:text-4xl mt-5 mb-7">IT 기획의 전단계를 <span class="text-blue-500">쉽게</span> 설명드립니다</h2>
            <p class="font-light text-gray-700 text-base md:text-lg">올립스는 200건 이상의 프로젝트를 진행하면서 쌓인 노하우와 실무문서로 <br class="hidden md:block">개발이 처음이신 대표님들도 쉽게 IT 기획 과정을 이해하시도록 안내드립니다.</p>
        </div>
    </div>
    <div class="inner">
        <h2 class="gmarket text-center font-bold text-2xl md:text-3xl lg:text-4xl">주요 플랫폼 포트폴리오</h2>
        <p class="text-gray-700 text-center text-base md:text-lg font-normal my-4 md:my-7">
            300여개의 플랫폼을 성공적으로 개발하였습니다.<br>
            반응형과 네이티브 앱을 포함하면 훨씬 많은 포트폴리오가 있지만,<br>
            3년 내에 진행된 모바일웹과 하이브리드앱 프로젝트만 소개합니다.
        </p>

        <div class="flex flex-wrap">
            <CardBox v-for="(card, index) in cards1" :key="index" :title="card.title" :date="card.date" :link="card.link" :portNm="card.name"/>
        </div>

        <h2 class="gmarket text-center font-bold text-2xl md:text-3xl lg:text-4xl mt-16">주요 공장 시스템 포트폴리오</h2>
        <p class="text-gray-700 text-center text-base md:text-lg font-normal my-7">
            300여개의 ERP, MES 공장 자동화를 성공적으로 개발하였습니다.<br>
            많은 포트폴리오가 있지만,<br>
            3년 내에 진행된 공장 스마트팩토리 프로젝트만 소개합니다.
        </p>

        <div class="flex flex-wrap">
            <CardBox v-for="(card, index) in cards2" :key="index" :title="card.title" :date="card.date" :link="card.link" :portNm="card.name" />
        </div>
    </div>
</template>

<script setup>
import CardBox from "@/components/CardBox.vue";

const cards1 = [
    {title: "플랜 갤러리 (업데이트 예정)", date: "2022-10-01", link : ""},
    {title: "대리 시공앱 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "플랜 인테리어 플랫폼", date: "2022-10-01", link: "/portfolio/detail", name : 'planorder'},
    {title: "요양일기 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "세차콕 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "미트볼 야구 매칭 플랫폼", date: "2022-10-01", link: "/portfolio/detail", name : 'baseball'},
    {title: "보험앱 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "위클즈", date: "2022-10-01", link: "/portfolio/detail", name : 'wecls'},
    {title: "태깅박스 팀협업 플랫폼 (업데이트 예정)", date: "2022-10-01", link: ""},
];

const cards2 = [
    {title: "피크업 (업데이트 예정)", date: "2022-10-01", link : ""},
    {title: "TMS 배기가스 관리 시스템 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "고려기업 조선 MES", date: "2022-10-01", link: "/portfolio/detail", name : 'kory'},
    {title: "금강정밀 금속가공 MES (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "비규격 차양산업 MES (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "텍스트웨이 플랫폼 (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "디스모먼트 침구 MES (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "해다은 수산 식품 MES (업데이트 예정)", date: "2022-10-01", link: ""},
    {title: "3D 스페이스 프린팅 MES (업데이트 예정)", date: "2022-10-01", link: ""},
];
</script>

<style lang="scss" scoped>
.bg-first {
    background-image: url("@/assets/images/bg-banner.png");
    height: 52rem;
}

@include mobile{
    .bg-first{
        height: 30rem;
    }
}
</style>