<template>
    <header class="hidden md:block w-full bg-white shadow fixed top-0 left-0 z-10">
        <div class="inner py-3 flex justify-between">
            <h1 class="w-24">
                <a href="/"><img src="@/assets/images/logo-header.svg" alt="로고" title="로고" /></a>
            </h1>
            <ul class="flex items-center text-gray-950 space-x-10">
                <li>
                    <a href="/contact" class="font-bold">개발 문의</a>
                </li>
                <li>
                    <a href="/estimate" class="font-bold">플랫폼 견적 계산</a>
                </li>
                <li>
                    <a class="font-bold" @click="getScroll">포트폴리오</a>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderVue",
    methods : {
        getScroll() {
            const name = this.$route.name;

            if(name === 'MainPage')
            {
                window.scrollTo(0, 8400);
            }
        }
    }
}
</script>

<style scoped>

</style>