<template>
    <footer class="w-full text-left bg-blue-700 text-white">
        <div class="inner">
            <div class="py-16">
                <img src="@/assets/images/logo-footer.svg" alt="로고" title="로고" class="mb-5" />
                <div class="space-y-8">
                    <p class="opacity-80">
                        스타트업 전문 개발사 올립스가<br>
                        다년간의 스타트업 프로젝트 개발 경험으로 만든<br>
                        모바일 플랫폼 빌더이자 실시간 협업툴입니다.
                    </p>
                    <p class="opacity-80">
                        (주)올립스<br>
                        사업자등록번호 182-88-00260<br>
                        대표 하현수<br>
                        전화번호: 010-3445-2105
                    </p>
                    <p class="opacity-80">
                        부산광역시 수영구 수영로 411-1
                    </p>
                </div>
            </div>
            <p class="font-medium py-7">
                COPYRIGHT (c) Allips, ALL RIGHTS RESERVED.
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterVue"
}
</script>

<style scoped>

</style>