<template>
    <div>
        <label :for="id" class="font-bold text-xl">{{ label }}</label>
        <input class="bg-white mt-6 text-base py-3 px-5 block border w-full border-stone-400 rounded-lg" type="text" :id="id" :name="name" :placeholder="placeholder"/>
    </div>
</template>

<script>
export default {
    name : "InputBox",
    props: {
        label      : String,
        id         : String,
        name       : String,
        placeholder: String,
    }
}
</script>

<style scoped>

</style>