<template>
    <div class="py-2.5 bg-zinc-100 md:mt-14">
        <div class="inner bg-white pt-5 pb-60 px-4">
            <NoticeBox :lists="noticeList" class="mb-2.5 "/>
            <div class="relative">
                <PayContent />
            </div>
        </div>
    </div>
</template>

<script setup>
import NoticeBox from "@/components/NoticeBox.vue";
import PayContent from "@/views/estimate/PayContent.vue";

const noticeList = [
    {content: "예상 견적은 클라이언트가 구현하고자 하는 기능등을 알아보기 위한 수단입니다."},
    {content: "개발문의 후 올립스에서 정확한 견적 산출을 위해 도움드리니, 희망하시는 기능을 추가해보세요."},
    {content: "프론트엔드: 기본개발, vue,js, react, 컴포넌트 개발을 선택할 수 있습니다."},
    {content: "백엔드: JAVA, PHP, Python 개발을 선택할 수 있습니다."},
    {content: "서버: Firedase, Supadase, Amazon 등 최적의 클라우드 서버를 선택할 수 있습니다."},
]
</script>

<style scoped>

</style>