/**
 * @description 포트폴리오 정보 관련 vuex
 */
const portfolioModule = {
    namespaced : true, 
    state : {
        list : [
            {
                mainTitle : '플랫폼',
                data      : [
                    { key : 'A01', title : '모바일웹', pay : 0, memo : 'react, vue.js 모바일 1식' },
                    { key : 'A02', title : '모바일앱', pay : 500, memo : '하이브리드 앱, 아이폰/안드로이드 패키징 출시' },
                ]
            },
            {
                mainTitle : '구현할 페이지 분량',
                data      : [
                    { key : 'B01', title : '20 페이지 이하', pay : 800, memo : '20 페이지의 프론트엔드' },
                    { key : 'B02', title : '30 페이지 이하', pay : 1200, memo : '30 페이지의 프론트엔드' },
                    { key : 'B03', title : '40 페이지 이하', pay : 1600, memo : '40 페이지의 프론트엔드' },
                    { key : 'B04', title : '60 페이지 이하', pay : 2400, memo : '60 페이지의 프론트엔드' },
                    { key : 'B05', title : '80 페이지 이하', pay : 3200, memo : '80 페이지의 프론트엔드' },
                    { key : 'B06', title : '100 페이지 이하', pay : 4000, memo : '100 페이지의 프론트엔드' },
                ]
            },
            {
                mainTitle : '디자인',
                data      : [
                    { key : 'C01', title : '디자인 템플릿 이용', pay : 200, memo : '올립스 템플릿을 이용해 약간의 커스터마이징' },
                    { key : 'C02', title : '고객 디자인 간략하게 반영', pay : 500, memo : '고객사 디자인을 간략하게 반영' },
                    { key : 'C03', title : '고객 맞춤 디자인 시스템', pay : 1000, memo : '고객사 디자인을 전용 시스템' },
                ]
            },
            {
                mainTitle : '관리자/업체 기능 구현',
                data      : [
                    { key : 'D01', title: '관리 메뉴', pay: 500, memo: '주요 데이터에 대한 조회/편집/삭제/검색/정렬 기능' },
                    { key : 'D02', title: '재고 관리', pay: 200, memo: '상품 재고 및 판매 수량 관리' },
                    { key : 'D03', title: '상품 관리', pay: 1000, memo: '업체/판매자가 상품 정보를 등록하고 관리하는 기능' },
                    { key : 'D04', title: '주문 관리', pay: 200, memo: '업체/판매자가 주문내역을 관리하는 기능' },
                    { key : 'D05', title: '배송 관리', pay: 200, memo: '배송 상태 관리 및 송장번호 입력 관리' },
                    { key : 'D06', title: '정산 관리', pay: 300, memo: '판매자/업체의 대금 정산을 위해 주별/일별 정산금액 관리' },
                    { key : 'D07', title: '통계', pay: 100, memo: '일 단위로 몇 개의 데이터를 합산해 개수와 금액을 표시' },
                    { key : 'D08', title: '모바일앱', pay: 500, memo: '하이브리드 앱, 아이폰/안드로이드 패키징 출시' }
                ]
            },
            {
                mainTitle: '편집/업로드 기능',
                data: [
                    { key : 'E01', title: '게시물 등록', pay: 500, memo: '상품 등록, 게시물 등록, 커뮤니티 생성 등' },
                    { key : 'E02', title: '텍스트 에디터', pay: 200, memo: '볼드, 이텔릭, 폰트 색상 등의 스타일을 적용하는 에디터' },
                    { key : 'E03', title: '단일 이미지/파일 첨부', pay: 50, memo: '이미지/파일 한 개씩 업로드하는 기능' },
                    { key : 'E04', title: '여러 이미지/파일 첨부', pay: 150, memo: '갤러리에서 여러 개 선택해서 업로드하는 기능' },
                    { key : 'E05', title: '300메가 이상의 파일 업로드', pay: 100, memo: '단순 파일 첨부가 아니라 별도의 업로드 프로세스 처리' },
                    { key : 'E06', title: '그래프', pay: 100, memo: '데이터의 복잡도에 따라 실제 공수와 금액이 달라짐' }
                ]
            },
            {
                mainTitle: 'O2O/커뮤니티 기능 관련',
                data: [
                    { key : 'F01', title: '견적 산출', pay: 200, memo: '사용자가 여러 항목을 선택해 견적을 산출하는 기능' },
                    { key : 'F02', title: '게시물 피드', pay: 150, memo: '인스타그램과 유사한 형태의 게시물 피드 화면' },
                    { key : 'F03', title: '경매/역경매', pay: 150, memo: '가격/조건으로 비딩을 제안하는 기능' },
                    { key : 'F04', title: '질문/답변', pay: 150, memo: '지식인, 스택오버플로우 같은 질문, 답변 기능' },
                    { key : 'F05', title: '커뮤니티', pay: 200, memo: '그룹 입장 및 그룹 내 게시판, 맴버 관리 등' },
                    { key : 'F06', title: '게이미피케이션', pay: 150, memo: '활동에 따라 뱃지/등급을 부여' },
                    { key : 'F07', title: '컨텐츠 열람 권한', pay: 150, memo: '결제/구독 후 컨텐츠 열람' },
                    { key : 'F08', title: '초대', pay: 200, memo: '사용자별 초대코드를 생성하고 가입 시 리워드 지급' }
                ]
            },
            {
                mainTitle: '고급 기능',
                data: [
                    { key : 'G01', title: '캘린더 UI 기능', pay: 150, memo: '캘린더로 기간 선택이 필요한 경우' },
                    { key : 'G02', title: '지도 표시', pay: 100, memo: '지도 위에 한 개의 정보 팝업을 띄움' },
                    { key : 'G03', title: '지도와 목록의 연동', pay: 300, memo: '지도 위의 핀들과 검색 결과 목록을 연동' },
                    { key : 'G04', title: '앱 실행시에 현위치 파악', pay: 150, memo: '백그라운드가 아닌 앱 실행시에 현재 위치를 가져오는 기능' },
                    { key : 'G05', title: '드래그/드롭 위치 저장', pay: 200, memo: '드래그/드롭 데이터 위치를 변경하고 저장' },
                    { key : 'G06', title: '딥러닝 연동', pay: 800, memo: '이미지 인식, 자세 인식, 물체 인식 등을 위해 딥러닝을 적용' },
                    { key : 'G07', title: '복잡한 계산 수식', pay: 200, memo: '복잡한 계산에 의한 결과값 도출이 필요한 경우' }
                ]
            },
            {
                mainTitle: '소셜 기능 관련',
                data: [
                    { key : 'H01', title: '1:1 채팅', pay: 300, memo: '1:1 사용자간의 실시간 채팅' },
                    { key : 'H02', title: '그룹 채팅', pay: 800, memo: '그룹 채팅, 입장/퇴장 등 기능' },
                    { key : 'H03', title: '채팅 특수 기능', pay: 200, memo: '채팅에 추가되는 특수한 기능' },
                    { key : 'H04', title: '팔로잉', pay: 100, memo: '회원 또는 상점을 팔로잉' },
                    { key : 'H05', title: '웹 공유 기능', pay: 100, memo: '웹에서 페이스북, 트위터에 링크 공유' },
                    { key : 'H06', title: '앱 공유 기능', pay: 800, memo: '앱에서 페이스북, 카카오톡 공유' },
                    { key : 'H07', title: '권한 및 등급 관리', pay: 200, memo: '사용자별로 접근 권한을 분리, 복잡해지면 비용 증가' },
                    { key : 'H08', title: '딥링크', pay: 100, memo: '푸시 등 앱링크 클릭시 앱의 특정 페이지로 이동' }
                ]
            },
            {
                mainTitle: '네이티브 기능 관련',
                data: [
                    { key : 'I01', title: '블루투스 연동', pay: 800, memo: 'iot/웨어러블 장치와 블루투스로 연결' },
                    { key : 'I02', title: 'QR/바코드 인식', pay: 300, memo: 'QR/바코드 인식하여 페이지 이동 또는 동작' },
                    { key : 'I03', title: '백그라운드 위치 추적', pay: 300, memo: '사용자 위치를 주기적으로 서버로 전송' },
                    { key : 'I04', title: '백그라운드 음성 재생', pay: 300, memo: '앱 비활성화시에도 음성의 지속적인 재생' },
                    { key : 'I05', title: '앱 내에서 외부 웹 표시', pay: 300, memo: '앱에서 별도의 웹뷰로 페이지 표시' },
                    { key : 'I06', title: '네이티브 카메라 활용', pay: 800, memo: '카메라 촬영 UI 및 동작을 커스터마이징' },
                    { key : 'I07', title: '네이티브 SDK 연동', pay: 500, memo: '보상형 광고 등 네이티브 SDK 연동' }
                ]
            },
            {
                mainTitle   : '목록/상세 관련',
                data        : [
                    { key : 'J01', title: '목록 화면', pay: 100, memo: '상품, 상점, 식당, 호텔 등의 목록 페이지' },
                    { key : 'J02', title: '상세 화면', pay: 100, memo: '상품, 상점, 식당, 호텔 등의 상세 페이지' },
                    { key : 'J03', title: '찜하기', pay: 100, memo: '찜하고 마이페이지에서 확인하는 기능' },
                    { key : 'J04', title: '목록 관리', pay: 150, memo: '폴더나 컬렉션, 주소 목록을 관리하는 기능' },
                    { key : 'J05', title: '댓글', pay: 100, memo: '게시물이나 아이템에 댓글 기능과 관리페이지' },
                    { key : 'J06', title: '리뷰', pay: 200, memo: '별점 리뷰 기능과 관리페이지' },
                    { key : 'J07', title: '상품옵션', pay: 200, memo: '상품에 구매 옵션이 있을 때' },
                    { key : 'J08', title: '검색', pay: 100, memo: '한 종류의 데이터를 제목/설명 검색' },
                    { key : 'J09', title: '통합검색', pay: 200, memo: '여러 종류의 데이터를 검색' },
                    { key : 'J10', title: '최근 또는 인기 검색어', pay: 100, memo: '최근 검색이나 인기 검색어' },
                    { key : 'J11', title: '검색어 자동완성', pay: 150, memo: '일부 글자를 입력하면 검색어 추천' },
                    { key : 'J12', title: '예약하기', pay: 100, memo: '날짜 또는 시간까지 장소 등을 예약하는 기능' },
                    { key : 'J13', title: '신고하기', pay: 100, memo: '게시물/사용자 신고 기능 (애플 출시 필수)' },
                    { key : 'J14', title: '차단하기', pay: 150, memo: '게시물/사용자 차단 기능 (애플 출시 필수)' },
                    { key : 'J15', title: '랭킹', pay: 150, memo: '조회수, 점수, 구매수 등으로 랭킹 표시' },
                    { key : 'J16', title: '추천/연관 컨텐츠', pay: 100, memo: '좋아할만한, 연관된 상품이나 게시물 표시' },
                    { key : 'J17', title: '상세 필터링', pay: 200, memo: '가격, 카테고리, 부가 정보를 바탕으로 필터링' },
                    { key : 'J18', title: '거리순 필터링/정렬', pay: 200, memo: '거리순 정렬/필터링 하는 기능' }
                ]
            },
            {
                mainTitle: '결제 관련',
                data: [
                    { key : 'K01', title: '국내카드 결제', pay: 100, memo: '토스 페이먼츠를 연동해 국내 카드 결제' },
                    { key : 'K02', title: '가상계좌 결제', pay: 400, memo: '자동 입금 확인, 입금전/후 상태 관리 필요' },
                    { key : 'K03', title: '페이팔/해외카드 결제', pay: 100, memo: '페이팔을 연동하여 해외 신용카드와 페이팔 결제' },
                    { key : 'K04', title: '실시간계좌/휴대폰 결제', pay: 100, memo: '휴대폰 결제와 실시간 계좌이체 각각' },
                    { key : 'K05', title: '적립금/포인트 제도', pay: 200, memo: '적립금 지급 및 차감. 내역표시 등' },
                    { key : 'K06', title: '쿠폰 결제', pay: 200, memo: '쿠폰 정책이 다양해지면 비용 증가' },
                    { key : 'K07', title: '정기/구매시 자동 결제', pay: 300, memo: '카드 번호를 입력한 뒤 매월 또는 구매시 자동으로 결제' },
                    { key : 'K08', title: '인앱 결제', pay: 800, memo: '디지털 컨텐츠는 앱스토어 출시 시 인앱결제 필수' },
                    { key : 'K09', title: '판매자 지급대행', pay: 1000, memo: 'PG사 연동을 통한 판매자별 자동 정산' },
                    { key : 'K10', title: '장바구니 기능', pay: 300, memo: '장바구니 담고 항묵 삭제, 개수 변경' },
                    { key : 'K11', title: '결제 전체 취소', pay: 200, memo: '하나의 주문을 전체 취소' },
                    { key : 'K12', title: '결제 부분 취소', pay: 500, memo: '주문 내역서 일부 항목만 결제 취소' }
                ]
            },
            {
                mainTitle: 'API, 크롤링, 문서 관련',
                data: [
                    { key : 'L01', title: '단순한 API 연동', pay: 200, memo: '주고 받는 데이터가 단순한 경우' },
                    { key : 'L02', title: '복잡한 API 연동', pay: 500, memo: '인증 절차가 있고 주고 받는 데이터가 복잡한 경우' },
                    { key : 'L03', title: '주기적인 데이터 처리/알림', pay: 200, memo: '주기적으로 API처리 또는 메시지 발송' },
                    { key : 'L04', title: '크롤링', pay: 300, memo: '웹페이지를 분석해 데이터를 가져옴' },
                    { key : 'L05', title: '스트래핑', pay: 500, memo: '크롤링이 불가한 경우 가상 브라우저로 웹 컨텐츠를 가져옴' },
                    { key : 'L06', title: 'PDF/이미지 생성', pay: 200, memo: 'PDF나 이미지를 만들어내는 경우' },
                    { key : 'L07', title: '간단한 엑셀 임포트/익스토트', pay: 300, memo: '엑셀로 데이터를 등록하거나 내려받기' }
                ]
            },
            {
                mainTitle: '국제화 관련',
                data: [
                    { key : 'N01', title: '메뉴명 다국어 관리', pay: 800, memo: '메뉴명 및 사이드 문구를 다국어로 관리' },
                    { key : 'N02', title: '게시물 다국어 관리', pay: 800, memo: '게시물, 상품 정보 등을 언어별로 분리하여 관리' },
                    { key : 'N03', title: '국가별 시간대 적용', pay: 300, memo: '사용자별 시간대 성정 및 표시' }
                ]
            }
        ]
    }
};
  
export default portfolioModule;