<template>
    <div class="pt-14 pb-28 bg-zinc-100 mt-14">
        <div class="inner text-left">
            <h5 class="font-bold">포트폴리오</h5>
            <h2 class="font-bold text-xl mt-3 mb-2.5">{{ data['title'] }}</h2>
            <h5>{{ data['memo'] }}</h5>
            <p class="text-xs text-gray-600">착수일: {{ data['date'] }} </p>
            <div class="flex flex-wrap flex-row mt-5">
                <div v-for="(card, index) in data['images']" :key="index" class="rounded-xl w-60 h-100 py-3 px-2.5 bg-white shadow-2xl text-center items-center flex" :class="data['gubun'] === 'web' ? 'webCard' : 'mobileCard'">
                    <div class="overflow-hidden">
                        <img :src="require(`@/assets/images/${card.image}.png`)" :alt="card.alt" :title="card.alt" class="w-full h-full duration-200 hover:scale-105" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store     = useStore();
const route     = useRoute();
const portfolio = computed(() => store.state.portfolio);
const data      = portfolio.value[route.params.portNm];

onMounted(() => {
    window.scrollTo(0, 0);
});
</script>

<style lang="scss" scoped>
.webCard{
    margin-right: 21px;
    margin-bottom: 21px;
    max-width: 450px;
    height: 100%;
    width: 100%;

    &:nth-child(4n){
        margin-right: 0;
    }
}

.mobileCard{
    margin-right: 21px;
    margin-bottom: 21px;

    &:nth-child(4n){
        margin-right: 0;
    }
}
</style>