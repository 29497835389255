<template>
    <button type="button" class="py-5 px-4 shadow-md pay-button rounded mb-3.5 text-left hover:bg-blue-50 duration-200 border" :class="{ 'bg-blue-50 border-blue-500' : isButtonClick }" @click="toggleButtonClicks">
        <div class="flex items-center">
            <h3 class="font-bold w-2/3">{{ title }}</h3>
            <h3 class="font-bold w-1/3 text-right text-lg text-gray-500">{{ pay }} <span class="text-sm">만원</span></h3>
        </div>
        <p class="text-xs text-zinc-500 mt-2">
            {{ description }}
        </p>
    </button>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit          = defineEmits(['payBoxClick']);
const isButtonClick = ref(false);

const props = defineProps({
    code        : String,
    title       : String,
    description : String,
    pay         : String
});

const toggleButtonClicks = () => {
    isButtonClick.value = !isButtonClick.value;

    const params = {
        status      : isButtonClick.value,
        key         : props.code,
        amt         : props.pay,
        title       : props.title,
        description : props.description
    };

    emit('payBoxClick', params);
}
</script>

<style lang="scss" scoped>
.pay-button{
    width: 32.3%;
    margin-right: 1.4%;

    &:nth-child(3n){
        margin-right: 0;
    }
}

@include laptopToTablet{
    .pay-button{
        width: 48.7%;
        margin-right: 2.5%;

        &:nth-child(2n){
            margin-right: 0;
        }
    }
}

@include tabletToMobile{
    .pay-button{
        width: 48.7%;
        margin-right: 2.5%;

        &:nth-child(2n){
            margin-right: 0;
        }
    }
}

@include mobile{
    .pay-button{
        width: 98%;
        margin: 0 auto 15px auto;

        &:nth-child(3n){
            margin-right: auto;
        }
    }
}
</style>