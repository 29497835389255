<template>
    <div class="bg-first w-full bg-cover bg-no-repeat bg-center flex items-center">
        <div class="inner text-center">
            <img src="@/assets/images/icon-circle02.png" alt="02번" title="02번" class="mx-auto" />
            <h2 class="font-semibold text-gray-900 text-xl md:text-3xl lg:text-4xl mt-5 mb-7">프로젝트의 리스크를 줄이고 효율성을 높이는 기능</h2>
            <p class="font-light text-gray-700 text-base md:text-lg">쉽고 빠른 프로토타이핑, 빠른 실서버 시연, 개발 진척도 추적, 유연한 유지보수<br>정확하고 신속한 개발을 진행해 드립니다.</p>
        </div>
    </div>
    <div class="py-16 md:py-28">
        <div class="inner">
            <div class="text-center">
                <h2 class="text-gray-900 font-bold text-xl md:text-2xl lg:text-3xl">프로젝트의 리스크를 줄이고 효율성을 높이는 기능</h2>
                <p class="text-sm md:text-base">올립스를 통해 진행하면 프로젝트 매니징이 이렇게 쉬워집니다.</p>
            </div>

            <ul class="mt-16 space-y-10 md:space-y-16">
                <li class="lg:flex justify-between" data-aos="fade-up-left">
                    <div class="w-80">
                        <img src="@/assets/images/icon-num01.png" alt="1번" title="1번" />
                        <h2 class="gmarket font-bold text-xl md:text-2xl lg:text-3xl mt-4 mb-3 md:mb-7 text-gray-800">쉽고 빠른 프로토타이핑</h2>
                        <p class="text-gray-800 mb-3 md:mb-0 text-sm md:text-lg">기획서가 준비되어있지 않아도 원하는 템플릿을 통해서 기획하세요. 올립스에서는 300페이지 가량의 완성된 앱 UI를 제공합니다.</p>
                    </div>
                    <img src="@/assets/images/image-main04.png" alt="1번 이미지" title="1번 이미지" class="mx-auto shadow-md rounded-xl" />
                </li>
                <li class="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row justify-between" data-aos="fade-up-right">
                    <img src="@/assets/images/image-main05.png" alt="2번 이미지" title="2번 이미지" class="mx-auto shadow-md rounded-xl" />
                    <div class="w-80">
                        <img src="@/assets/images/icon-num02.png" alt="2번" title="2번" />
                        <h2 class="gmarket font-bold text-xl md:text-2xl lg:text-3xl mt-4 mb-3 md:mb-7 text-gray-800">빠른 실서버 시연</h2>
                        <p class="text-gray-800 mb-3 md:mb-0 text-sm md:text-lg">개발이 진행되는 동안에도 진행된 결과물을 확인하며 테스트와 시연을 하고 싶나요? 프로젝트 생성 후 일주일만에 실서버 배포로 실제 브라우저에서 동작하는 서비스를 확인할 수 있습니다.</p>
                    </div>
                </li>
                <li class="md:flex justify-between" data-aos="fade-up-left">
                    <div class="w-80">
                        <img src="@/assets/images/icon-num03.png" alt="3번" title="3번" />
                        <h2 class="gmarket font-bold text-xl md:text-2xl lg:text-3xl mt-4 mb-3 md:mb-7 text-gray-800">개발 진척도 추적</h2>
                        <p class="text-gray-800 mb-3 md:mb-0 text-sm md:text-lg">개발자의 테스크 진행사항을 통해 진척도를 확인하며 일정을 확인하세요. 견적은 개발 요청 시 산출하며 견적 항목이 테스크로 분류됩니다.</p>
                    </div>
                    <img src="@/assets/images/image-main06.png" alt="3번 이미지" title="3번 이미지" class="shadow-md rounded-xl" />
                </li>
                <li class="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row justify-between" data-aos="fade-up-right">
                    <img src="@/assets/images/image-main07.png" alt="4번 이미지" title="4번 이미지" class="mx-auto shadow-md rounded-xl" />
                    <div class="w-80">
                        <img src="@/assets/images/icon-num04.png" alt="4번" title="4번" />
                        <h2 class="gmarket font-bold text-xl md:text-2xl lg:text-3xl mt-4 mb-3 md:mb-7 text-gray-800">유연한 유지보수</h2>
                        <p class="text-gray-800 mb-3 md:mb-0 text-sm md:text-lg">코멘트 기능, 채팅 기능을 통해 실시간으로 개발자와 협업하세요. 기획서를 따로 작성하거나 개발자와 통화하는 과정 없이, 수정을 원하는 페이지에 레퍼런스 이미지를 업로드하거나 코멘트를 달 수 있습니다.</p>
                    </div>
                </li>
            </ul>

            <div class="py-16 md:py-20 lg:py-28">
                <img src="@/assets/images/image-point04.png" alt="포인트4" title="포인트4" class="mb-6" />
                <div class="flex flex-wrap justify-between">
                    <h2 class="text-2xl md:text-3xl font-semibold w-full md:w-2/6">
                        합리적인 비용으로<br>
                        첫 IT개발 부담스럽지 않게
                    </h2>
                    <p class="w-full md:w-3/6 text-base md:text-xl text-gray-700">
                        첫 IT 서비스에 많은 금액을 한번에 사용하시기는 부담스럽습니다. (주)올립스만의 빠르고 효율적인 개발 프로세스로 초기 기업에게 최적화되어 있는 합리적인 견적을 제안드립니다.
                    </p>
                </div>
                <img src="@/assets/images/image-main08.png" alt="프로세스1" title="프로세스1" class="mt-20 mb-14" />
                <img src="@/assets/images/image-main09.png" alt="프로세스2" title="프로세스2" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainSecond"
}
</script>

<style lang="scss" scoped>
.bg-first {
    background-image: url("@/assets/images/bg-banner.png");
    height: 52rem;
}

@include mobile{
    .bg-first{
        height: 30rem;
    }
}
</style>