<template>
    <div class="pt-14 pb-28 bg-zinc-100 md:mt-14">
        <div class="inner text-center">
            <p class="text-sm font-bold text-sky-500">CONTACT</p>
            <h2 class="text-2xl lg:text-3xl font-bold mt-5 mb-10">📬 플랜오더 문의하기</h2>
            <p class="text-lg font-medium">궁금한 사항이 있으시다면 언제든지 문의해주세요. 저희는 항상 여러분의 의견을 듣고자 합니다.</p>
            <div class="bg-white space-y-9 text-left w-11/12 md:w-4/5 lg:w-3/5 py-14 px-10 mx-auto mt-20 rounded-md shadow-md">
                <InputBox label="이메일 주소" placeholder="예. admin@gmail.com" />
                <TextareaBox label="서비스에 대한 설명" placeholder="예. 배달플랫폼 소비자와 매칭 플랫폼, 스마트팩토리" />
                <InputBox label="최대 예산" placeholder="예. 5000만원" />
                <InputBox label="담당자 전화번호" placeholder="담당자 분의 연락처를 입력해주세요" />
                <InputBox label="정부지원사업 예산 집행인 경우" placeholder="예. 2023 예비창업패키지 개발비 집행 예정" />
                <div>
                    <label for=" " class="font-bold text-xl">
                        개인정보 처리방침에 동의합니다
                        <span class="block font-normal text-sm text-zinc-400">(목적) 상담 | (항목) 이메일, 전화번호 | (보유 및 이용기간) 최초 동의 시로부터 6개월</span>
                    </label>
                </div>
                <button type="button" class="bg-zinc-900 rounded-lg text-white text-center w-full py-3 font-bold text-xl">문의 보내기</button>
            </div>
        </div>
    </div>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
import TextareaBox from "@/components/TextareaBox.vue";

export default {
    name: "ContactPage",
    components: {TextareaBox, InputBox}
}
</script>

<style scoped>

</style>