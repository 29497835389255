<template>
    <div>
        <label :for="id" class="font-bold text-xl">{{ label }}</label>
        <textarea class="bg-white resize-none mt-6 h-28 text-base py-3 px-5 block border w-full border-stone-400 rounded-lg" type="text" :id="id" :name="name" :placeholder="placeholder"/>
    </div>
</template>

<script>
export default {
    name: "TextareaBox",
    props: {
        label      : String,
        id         : String,
        name       : String,
        placeholder: String,
    }
}
</script>

<style scoped>

</style>