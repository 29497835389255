<template>
    <MainBanner/>
    <MainFirst/>
    <MainSecond/>
    <!-- third 포트폴리오 파트 -->
    <MainThird/>
    <MainFourth/>
    <MainFifth/>
</template>

<script>
import MainBanner from "@/views/main/MainBanner.vue";
import MainFirst from "@/views/main/MainFirst.vue";
import MainSecond from "@/views/main/MainSecond.vue";
import MainThird from "@/views/main/MainThird.vue";
import MainFourth from "@/views/main/MainFourth.vue";
import MainFifth from "@/views/main/MainFifth.vue";

export default {
    name      : "MainPage",
    components: {MainFifth, MainFourth, MainThird, MainSecond, MainFirst, MainBanner}
}
</script>

<style scoped>

</style>