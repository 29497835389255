<template>
    <div class="bg-first w-full md:bg-cover bg-no-repeat bg-center flex items-center">
        <div class="inner text-center">
            <img src="@/assets/images/icon-circle01.png" alt="01번" title="01번" class="mx-auto" />
            <h2 class="font-semibold text-gray-900 text-xl md:text-3xl lg:text-4xl mt-5 mb-7">IT 기획의 전단계를 <span class="text-blue-500">쉽게</span> 설명드립니다</h2>
            <p class="font-light text-gray-700 text-base md:text-lg">올립스는 200건 이상의 프로젝트를 진행하면서 쌓인 노하우와 실무문서로 <br class="hidden md:block">개발이 처음이신 대표님들도 쉽게 IT 기획 과정을 이해하시도록 안내드립니다.</p>
        </div>
    </div>
    <div>
        <div class="inner space-y-20 md:space-y-56 lg:space-y-72">
            <div class="md:flex items-center" data-aos="fade-up-right">
                <img src="@/assets/images/image-main01.png" class="w-4/5 mx-auto md:w-1/2" alt="" title=""/>
                <div class="md:ml-10 lg:ml-20">
                    <img src="@/assets/images/image-point01.png" alt="" title="" class="hidden md:block" />
                    <h2 class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl my-6 leading-10">
                        어디서부터 시작할지 모르면<br class="hidden md:block">
                        템플릿부터 받아보세요
                    </h2>
                    <p class="text-sm lg:text-xl text-gray-600">
                        아이디어만 있는데 구체화할 방법이 없으신가요?<br>
                        쇼핑몰, SNS, 매칭 플랫폼 등 다양한 서비스에 대한<br>
                        (주)올립스의 템플릿을 받아보세요!
                    </p>
                </div>
            </div>
            <div class="md:flex items-center" data-aos="fade-up-left">
                <img src="@/assets/images/image-main02.png" class="w-4/5 mx-auto md:w-1/2" alt="" title=""/>
                <div class="md:ml-10 lg:ml-20">
                    <img src="@/assets/images/image-point02.png" alt="" title="" class="hidden md:block" />
                    <h2 class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl my-6 leading-10">
                        전담PM과의<br class="hidden md:block">
                        상시 소통 및 미팅
                    </h2>
                    <p class="text-sm lg:text-xl text-gray-600">
                        개발 경험이 없으신 대표님들을 위해<br>
                        디자이너 및 개발자와의 소통을 조율할<br>
                        전담 PM과 정기적인 미팅이 진행됩니다.
                    </p>
                </div>
            </div>
            <div class="md:flex items-center" data-aos="fade-up-right">
                <img src="@/assets/images/image-main03.png" class="w-4/5 mx-auto md:w-1/2" alt="" title=""/>
                <div class="md:ml-10 lg:ml-20">
                    <img src="@/assets/images/image-point03.png" alt="" title="" class="hidden md:block" />
                    <h2 class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl my-6 leading-10">
                        개발 뉴비에게도 쉬운<br class="hidden md:block">
                        실무문서로 투명한 소통
                    </h2>
                    <p class="text-sm lg:text-xl text-gray-600">
                        아이디어만 있는데 구체화할 방법이 없으신가요?<br>
                        쇼핑몰, SNS, 매칭 플랫폼 등 다양한 서비스에 대한<br>
                        (주)올립스의 템플릿을 받아보세요!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainFirst"
}
</script>

<style lang="scss" scoped>
.bg-first {
    background-image: url("@/assets/images/bg-banner.png");
    height: 52rem;
}

@include mobile{
    .bg-first{
        height: 30rem;
    }
}
</style>