<template>
    <div class="bg-white">
        <HeaderVue/>
        <div class="section">
            <router-view></router-view>
        </div>
        <FooterVue v-if="showFooter"/>
    </div>
</template>

<script>

import HeaderVue from "@/views/common/Header.vue";
import FooterVue from "@/views/common/Footer.vue";

import AOS from 'aos';
import 'aos/dist/aos.css'

export default {
    name      : 'App',
    components: {
        FooterVue,
        HeaderVue
    },
    mounted() {
        AOS.init({
            duration       : 1500,
            once           : true,
            anchorPlacement: "top-top",
            disable        : false,
        });
    },
    computed: {
        showFooter() {
            const currentPath = this.$route.path
            return !currentPath.startsWith('/estimate')
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/GlobalStyle.css";
</style>
