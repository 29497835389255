<template>
    <div class="w-full h-screen bg-black/50 z-10 fixed top-0 left-0 flex items-center print:hidden">
        <div id="download" class="bg-white w-11/12 md:w-4/5 lg:w-1/2 xl:w-1/3 mx-auto min-h-screen max-h-screen py-9 px-6 rounded-lg overflow-y-scroll overscroll-none hidden-scroll print:block">
            <div class="flex justify-between align-middle mb-7">
                <h2 class="font-bold text-xl exclude">예상 견적서</h2>
                <img class="cursor-pointer exclude" src="@/assets/images/icon-close.svg" alt="close" title="close" @click="closePayTable" />
            </div>
            <div class="space-y-4">
                <table>
                    <thead>
                    <tr>
                        <th>날짜</th>
                        <th>수신</th>
                        <th>유효기간</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td class="text-center">{{ getDate() }}</td>
                        <td class="text-center">귀하</td>
                        <td class="text-center">견적일로부터 30일</td>
                    </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th>사업자번호</th>
                        <th>상호</th>
                        <th>서명</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td class="text-center">362-81-00644</td>
                        <td class="text-center">(주) 올립스</td>
                        <td>
                            <img src="@/assets/images/image-sign.png" alt="직인" title="직인" class="mx-auto" />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th class="w-2/5">기능명</th>
                        <th class="text-left w-2/5">설명</th>
                        <th class="text-left w-1/5">비용</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(item, index) in list" :key="index">
                        <td>{{ item.title }}</td>
                        <td>{{ item.description }}</td>
                        <td class="font-bold">{{ getComma(item.amt) }}만원</td>
                    </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th>개발 기간</th>
                        <th>공급가액 / 부가세</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td class="font-bold text-center">6개월</td>
                        <td class="font-bold text-lg text-center">{{ getComma(totalAmt) }}만원 <span class="text-zinc-400">/ {{ getComma(getVat(totalAmt)) }}만원</span></td>
                    </tr>
                    </tbody>
                </table>

                <ul class="text-sm font-bold text-zinc-400">
                    <li class="list-disc ml-6">상세 견적을 산출하기 전의 대략적인 예상 견적입니다.</li>
                    <li class="list-disc ml-6">상세 기획이 완성되면 저희가 꼼꼼하게 검토하여 세부적인 항목의 견적을 다시 산출하게 됩니다.</li>
                </ul>

                <button type="button" class="w-full rounded p-3 text-white text-sm font-bold bg-indigo-600 exclude" @click="getDownload">견적서 다운로드</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { defineProps, defineEmits } from 'vue';
import { getVatAmt, getCommas, getTodayDate } from '@/assets/js/function';

defineProps({
    totalAmt : Number,
    list     : Array
});

const emit = defineEmits(['close']);

const getComma = (amt) => {
    return getCommas(Number(amt));
}

const getDate = () => {
    return getTodayDate();
}

const getVat = (amt) => {
    return getVatAmt('Y', Number(amt));
}

const getDownload = async () => {
    const content   = document.getElementById('download');
    const excludes  = content.querySelectorAll('.exclude');

    // PDF로 변환하지 않을 요소 숨기기
    excludes.forEach(exclude => {
        exclude.style.display = 'none';
    });

    const canvas    = await html2canvas(content);
    const imgData   = canvas.toDataURL('image/png');
    const pdf       = new jsPDF();

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('(주)올립스_개발_견적서.pdf');

    excludes.forEach(exclude => {
        exclude.style.display = '';
    });
}

const closePayTable = () => {
    emit('close');
}
</script>

<style lang="scss" scoped>
.h-screen{
    height: 100vh;
}
table{
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    thead, tbody, tfoot{
        th, td{
            text-overflow:ellipsis;
            overflow:hidden;
            white-space:nowrap;
            font-size: 0.875rem;
        }

        th{
            padding: 0.5rem 0.6rem;
            background-color: #1E2430;
            text-align: center;
            color: #FFFFFF;
            font-weight: 700;
        }

        td{
            padding: 0.6rem;
            border-bottom: 1px solid #E9EAED;
            background-color: #FFFFFF;
        }
    }
}
</style>