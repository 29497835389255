<template>
    <a class="card text-center m-4 block" @click="getMoveUrl">
        <img src="@/assets/images/image-81.png" alt="" title="" class="rounded-md shadow-md" />
        <h5 class="mt-5 mb-3 font-bold text-lg">{{ title }}</h5>
        <p class="text-sm font-medium text-gray-400">착수일: {{ date }}</p>
    </a>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props  = defineProps({
    title   : String,
    date    : String,
    link    : String,
    portNm  : String
});

const getMoveUrl = () => {
    if(props.link !== '')
    {
        router.push({
            path   : `/portfolio/detail/${props.portNm}`
        });
    }
}
</script>

<style lang="scss" scoped>
.card{
    width: 30%;
}

@include mobile{
    .card{
        width: 95%;
    }
    img{
        margin: auto;
    }
}
</style>