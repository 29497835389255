<template>
    <div class="bg-white p-4 rounded-lg text-black w-5/12 text-left card shadow-lg mb-5">
        <h5 class="text-lg font-bold mb-3">Q. {{ title }}</h5>
        <p>A. {{ content }}</p>
    </div>
</template>

<script>
export default {
    name : "WhiteCard",
    props: {
        title  : String,
        content: String,
    }
}
</script>

<style lang="scss" scoped>
.card{
    width: 49%;
}

@include mobile {
    .card {
        width: 95%;
    }
}
</style>