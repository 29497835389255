<template>
    <div class="bg-first w-full bg-cover bg-no-repeat bg-center flex items-center">
        <div class="inner text-center">
        </div>
    </div>
    <div class="w-full text-center py-24 md:py-32 lg:py-40">
        <h2 class="font-semibold text-xl md:text-3xl lg:text-4xl mb-4">혹시 정부지원금을 사용하시나요?</h2>
        <p class="text-base md:text-lg text-gray-800">
            (주)올립스는 청창사, 창업패키지, 바우처 등 정부 지원사업의 IT 개발 전문 기업으로<br>
            사업비 사용계획 설계부터 집행에 필요한 10개 이상의 서류 준비까지 전부 지원해드립니다.
        </p>
    </div>

    <div class="bg-second w-full bg-cover bg-no-repeat bg-center">
    </div>

    <div class="text-center mt-16 mb-72">
        <h2 class="text-gray-900 font-semibold text-xl md:text-3xl lg:text-4xl"><span class="text-blue-500">200 +</span> 사의 기업이<br>올립스와 함께했습니다</h2>
    </div>
</template>

<script>
export default {
    name: "MainFourth"
}
</script>

<style lang="scss" scoped>
.bg-first {
    background-image: url("@/assets/images/bg-banner.png");
    height: 52rem;
}

.bg-second {
    background-image: url("@/assets/images/bg-banner2.png");
    height: 31rem;
}

@include mobile{
    .bg-first{
        height: 30rem;
    }
}
</style>