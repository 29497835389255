import { createStore } from 'vuex';
import portfolioModule from '@/modules/portfolioModule';
import payModule from '@/modules/payModule';

const store = createStore({
    modules: {
        portfolio : portfolioModule,
        pay       : payModule
    }
});

export default store;