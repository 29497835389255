import { createWebHistory, createRouter } from "vue-router";

import MainPage from "@/views/MainPage.vue";
import EstimateCalc from "@/views/EstimateCalc.vue";
import ContactPage from "@/views/ContactPage.vue";
import PortfolioDetail from "@/views/PortfolioDetail.vue";

const routes = [
    {
        path: '/',
        name: "MainPage",
        component: MainPage
    },
    {
        path: '/estimate',
        name: "EstimateCalc",
        component: EstimateCalc
    },
    {
        path: '/contact',
        name: "ContactPage",
        component: ContactPage
    },
    {
        path: '/portfolio/detail/:portNm',
        name: "PortfolioDetail",
        component: PortfolioDetail
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router