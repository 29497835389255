<template>
    <div class="mt-14 py-16 md:py-20 lg:py-28 text-center inner">
        <h1 class="text-black font-bold text-xl md:text-4xl lg:text-5xl gmarket leading-snug" data-aos="fade-down">
            어떤 서비스를 만들고 싶으신가요?<br>
            <span class="text-blue-500">올립스</span>가 도와드립니다<span class="text-blue-500">.</span>
        </h1>
        <a href="" class="border border-blue-500 rounded-lg py-3 px-6 bg-white shadow mt-10 inline-block font-medium hover:bg-blue-500 hover:text-white duration-200">개발 견적 문의하기</a>
    </div>

    <div class="py-16 md:py-20 lg:py-28 text-center inner">
        <h5 class="font-normal text-3xl md:text-4xl text-gray-800 mb-10">
            첫 개발 파트너로는 <span class="font-semibold block md:inline">어떤 파트너가 필요할까요?</span>
        </h5>
        <ul class="block md:flex justify-between">
            <li class="w-full md:w-80" data-aos="fade-down" data-aos-delay="300">
                <h2 class="font-semibold text-2xl mt-4 md:mb-10">기획은 <span class="text- blue-500">친절</span>하게</h2>
                <p class="font-normal">IT기획이 생소하신 대표님들께<br> 차근차근 함께 기획해 나갑니다.</p>
            </li>
            <li class="w-full md:w-80" data-aos="fade-down" data-aos-delay="400">
                <h2 class="font-semibold text-2xl mt-4 md:mb-10">디자인은 <span class="text-blue-500">트렌디</span>하게</h2>
                <p class="font-normal">2030 젊은 디자이너들의<br> 감각적인 결과물을 제공합니다.</p>
            </li>
            <li class="w-full md:w-80" data-aos="fade-down" data-aos-delay="500">
                <h2 class="font-semibold text-2xl mt-4 md:mb-10">개발은 <span class="text-blue-500">빠르게</span></h2>
                <p class="font-normal">대표님 첫 런칭에 꼭 필요한<br> 핵심 기능들을 스피디하게 개발합니다.</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "MainBanner"
}
</script>

<style scoped>

</style>