<template>
    <ul class="p-5 rounded bg-blue-100 text-sm">
        <li class="list-disc ml-5" v-for="(list, index) in lists" :key="index">
            {{ list.content }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "NoticeBox",
    props: {
        lists: Array,
    }
}
</script>

<style scoped>

</style>